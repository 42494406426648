import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultConfig,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";
import App from './App';

const BASESepoliaTestnet = {
  id: 84532,
  name: 'BASE Sepolia',
  nativeCurrency: { name: 'Base', symbol: 'BASE', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://rpc.notadegen.com/base/sepolia'] },
  }
}

const OPSepoliaTestnet = {
  id: 11155420,
  name: 'Optimism Sepolia',
  nativeCurrency: { name: 'Optimism', symbol: 'OP', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://optimism-sepolia.blockpi.network/v1/rpc/public'] },
  }
}

const config = getDefaultConfig({
  appName: 'My App',
  projectId: 'fba08911e2aad64000d0adf60fc08652',
  chains: [BASESepoliaTestnet, OPSepoliaTestnet],
  ssr: false, // If your dApp uses server side rendering (SSR)
});

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <App />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
